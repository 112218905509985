import React from 'react';
import { SiteMetadata } from '../components';
import { Layout } from '../layouts/Layout';

export default (props) => {
  return (
    <Layout>
      <SiteMetadata
        title="Submit a free streaming"
        description="Submit a free, or small donation fee based, theatre streamings that are being offer online during the COVID-19 pandemic"
        path={props.location.pathname}
      />

      <iframe
        title="Submit a show"
        className="container airtable-embed mt-4"
        src="https://airtable.com/embed/shrIz7N3TbsbTjI64?backgroundColor=pink"
        frameBorder="0"
        onMouseWheel=""
        width="100%"
        height="1900"
      ></iframe>
    </Layout>
  );
};
